
.train-manage {
    min-height: calc(100vh - 120px);
    background: #F7F9FB;
    .header{
        span{
            font-size: 14px;
        }
    }
    .train-txt{
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #333333;
        font-family: SourceHanSansCN-Medium, SourceHanSansCN;
        font-size: 20px;
        font-weight: 500;
        line-height: 1;
        margin: 20px 0;
        .reset-train {
            border: 1px solid #FD4446;
            padding: 10px 11px;
            color: #FD4446;
            &:hover {
                color: #fd4446d9;
                background-color: #fff;
                border: 1px solid #fd4446d9;
            }
        }
    }
    .train-container {
        background: #fff;
        padding: 20px;
        min-height: calc(100vh - 210px);
        /*height: calc(100vh - 189px);*/
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
    }
}
.train-dialog-content {
    display: flex;
    flex-direction: column;
    line-height: 2;
}
